<template>
    <div class="about">
      <div class="grey lighten-4">
        <v-container class="container-max-width py-0 pt-3 grey--text text--darken-2">
          <div class="grey lighten-4 py-1">
            <v-row no-gutters class="flex-nowrap" align="start">
          <span>
            <v-icon
              size="56px"
              color="primary"
              class="pr-4">
              mdi-account-card-details-outline
            </v-icon>
          </span>
              <div>
                <h3 class="text-uppercase">Інформація про нас</h3>
                <p class="mb-1">Короткі відомості про нас та наші контакти</p>
              </div>
            </v-row>
          </div>
        </v-container>
      </div>
<!--      <button @click="replaceUrl">DTKBRFRYJGRF</button>-->
      <v-container class="container-max-width">
        <v-card min-height="450px">
          <v-tabs color="primary">
            <v-tab>
              <v-icon left v-if="$vuetify.breakpoint.smAndUp">mdi-web</v-icon>
              Про нас
            </v-tab>
<!--            <v-tab>-->
<!--              <v-icon left v-if="$vuetify.breakpoint.smAndUp">mdi-comment-text-outline</v-icon>-->
<!--              Контакти-->
<!--            </v-tab>-->
            <v-tab>
              <v-icon left v-if="$vuetify.breakpoint.smAndUp">mdi-file-document-outline</v-icon>
              Підключення
            </v-tab>

            <v-tab>
              <v-icon left v-if="$vuetify.breakpoint.smAndUp">mdi-account-lock-outline</v-icon>
              Політика конфіденційності
            </v-tab>


            <v-tab-item>
              <v-card flat class="pt-0 pb-4" :class="`${$vuetify.breakpoint.xsOnly ? 'px-2' : 'px-4'}`">
                <v-card-text>
                  <v-container class="grid-list-lg">
                    <v-row dense justify="space-around">
                      <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12" class="mb-4">
<!--                        <span style="height: 22px; width: 100%; display: block"></span>-->
                        <v-card class="fill-height-90 mt-2" min-height="370px">
                          <v-list-item>
                            <v-list-item-avatar aspect-ratio="1" style="border-radius: 0" min-width="46px">
                              <span class="grey--text text--darken-2 headline"><strong style="letter-spacing: 1px">P</strong><strong class="green--text">24</strong></span>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="headline">ПП "Укрспецінфо"</v-list-item-title>
                              <v-list-item-subtitle>Сервіс "Єдина квитанція"</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-card-text>
                            <p>
                              Сервіс "Єдина квитанція", де Ви зможете швидко оплатити послуги комунальної сфери. Розробка білінгових систем для ОСББ, Управляючих компаній, водоканалів та суб’єктів постачання природного газу.
                            </p>
                            <h4 class="pb-1">Контактна інформація</h4>
                            <p>При винекнені проблем із оплатою або роботою сервісу - звертайтесь до нащого call центру, або пишіть нам на електронну пошту </p>
                            <v-row no-gutters  >
                              <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12" class="grey--text">
                                <p>
                                  <strong class="strong-color">Режим роботи call центру</strong><br>
                                  пн-пт 8:00-18:00<br>
                                  сб-нд 8:00-12:00
                                </p>
                                <p class="pb-1">
                                  <strong class="strong-color">Електронна пошта</strong><br>
                                  <a href="mailto: support@pay24.net.ua" class="grey--text">support@pay24.net.ua</a>
                                </p>
                              </v-col>
                              <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12">
                                <p class="pb-1 mb-0">47000, Україна, м. Тернопіль, вул. Медова, 2</p>
                                <ul>
                                  <li><a href="tel:+380962636049" class="grey--text">096 263 60 49</a></li>
                                </ul>
                              </v-col>
                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12" class="mb-4">
<!--                        <h3>Партнери:</h3>-->
<!--                        <span style="height: 22px; width: 100%; display: block"></span>-->
                        <v-card class="fill-height-90 mt-2" min-height="370px" >
                          <v-list-item>
                            <v-list-item-avatar style="border-radius: 0" min-width="100px">
                              <v-img
                                :src="`${publicPath}images/tas/image002_1.png`"
                                aspect-ratio="1"
                              >
                              </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="headline">ТАСКОМБАНК</v-list-item-title>
                              <v-list-item-subtitle>Разом зможемо все</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-card-text>
                            <p>
                              Усі платежі здіснються через процесінговий центр <a href="https://tascombank.ua/" target="_blank">платіжної системи</a>. Таскомбанк відповідає стандартам інформаційної безпеки PCI DSS 3.2, що гарантує повну безпеку ваших даних.
                            </p>
                            <h4 class="pb-1">Контактна інформація</h4>
                            <v-row no-gutters  >
                              <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12">
                                <p class="pb-1">
                                  <strong class="strong-color">Дзвінки за тарифами вашого оператора</strong><br>
                                  Безкоштовно зі стаціонарних телефонів<br>
                                  <a href="tel:0800214464" class="grey--text">0 (800) 503 580</a>
                                </p>
                                <p>
                                  <strong class="strong-color">Режим роботи інформаційного центру</strong><br>
                                  пн-пт 9:00-20:00<br>
                                  сб-нд 9:00-17:00
                                </p>
                              </v-col>
                              <v-col :cols="cols(0, 6, 12, 12)" md="6" sm="12" xs="12">
                                <p class="pb-1 mb-0">01032, м. Київ, вул. С.Петлюри, 30</p>
                                <ul>
                                  <li><a href="tel:+380442246728" class="grey--text"> 044 224 67 28</a></li>
                                  <li><a href="mailto: support@pay24.net.ua" class="grey--text">info@tascombank.com.ua</a></li>
                                  <li><a href="mailto: ecom.support@tascombank.com.ua" class="grey--text">ecom.support@tascombank.com.ua</a></li>
                                </ul>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
<!--            Тимчасово приховані контакти по областях-->
<!--            <v-tab-item>-->
<!--              <v-card flat class="pa-4">-->
<!--                <v-card-text>-->
<!--                  <v-select-->
<!--                    :items="regions"-->
<!--                    label="Область"-->
<!--                    prepend-icon=""-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    v-model="region"-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                  ></v-select>-->
<!--                </v-card-text>-->
<!--                <v-card-text>-->
<!--                  <v-container grid-list-xl class="pa-0">-->
<!--                    <v-row no-gutters   justify="center">-->
<!--                      <v-col md="6" sm="6" xs="12">-->
<!--                        <v-card class="fill-height">-->
<!--                          <v-list class="py-0" >-->
<!--                            <v-list-item v-for="(item, index) in contactData" :key="index" @click.stop="" class="px-4">-->
<!--                              <v-list-item-icon>-->
<!--                                <v-icon size="26px" :color="item.color">{{ item.icon }}</v-icon>-->
<!--                              </v-list-item-icon>-->
<!--                              <v-list-item-content>-->
<!--                                {{ item.value }}-->
<!--                              </v-list-item-content>-->
<!--                            </v-list-item>-->
<!--                          </v-list>-->
<!--                        </v-card>-->
<!--                      </v-col>-->
<!--                      <v-col md="6" sm="6" xs="12">-->
<!--                        <v-card class="fill-height px-4">-->
<!--                          <v-card-title>-->
<!--                            Зв'яжіться з нами!-->
<!--                          </v-card-title>-->
<!--                          <v-card-text>-->
<!--                            При виникнені питань чи зауважень звертайтеся до нас, контактні дані вказані у формі зліва. Такж рекомендуємо переглянути "Питання-Відповідді", можливо ваше питання є у списку.-->
<!--                          </v-card-text>-->
<!--                        </v-card>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-container>-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--            </v-tab-item>-->
<!--            Тимчасово приховані договора на підлючення-->
            <v-tab-item>
              <v-card flat class="px-5 py-4">
                <v-card-text class="pl-1 py-1">
                  <p class="ma-0 subtitle-1">Для підключення до мережі "Єдина квитанція" будь ласка ознайомтесь із переліком документів, який виложено нище.
                    Якщо виникнуть питання зверніться до нашого call-центру і наші спеціалісти вам допоможуть. Чекаємо на вас.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pl-1 pb-1 text-md-left text-sm-center text-xs-center">
                  <a target="_blank" download :href="`${publicPath}files/tas/pay_contract.pdf`" style="text-decoration: none">
                    <v-btn @click.stop="" class="success body-2 btn-center" depressed min-width="160px" :class="`${$vuetify.breakpoint.xsOnly ? 'mb-1 d-block' : 'mr-1'}`">
                      <v-icon left size="21px">mdi-file-pdf-outline</v-icon>
                      Договір
                    </v-btn>
                  </a>
<!--                  <a target="_blank" download :href="`${publicPath}files/ua_pay/questionnaire.pdf`" style="text-decoration: none">-->
<!--                    <v-btn @click.stop="" class="success body-2 btn-center" depressed min-width="160px" :class="`${$vuetify.breakpoint.xsOnly ? 'd-block' : 'ml-1'}`">-->
<!--                      <v-icon left size="21px">mdi-file-pdf-outline</v-icon>-->
<!--                      Опитувальник-->
<!--                    </v-btn>-->
<!--                  </a>-->
                </v-card-text>
                <v-card-text class="pl-1 pt-1 text-md-left text-sm-center text-xs-center">
                  <a target="_blank" download :href="`${publicPath}files/tas/pay_contract.docx`" style="text-decoration: none">
                    <v-btn @click.stop="" class="primary body-2 btn-center" depressed min-width="160px" :class="`${$vuetify.breakpoint.xsOnly ? 'mb-1 d-block' : 'mr-1'}`">
                      <v-icon left size="21px">mdi-file-word</v-icon>
                      Договір
                    </v-btn>
                  </a>
<!--                  <a target="_blank" download :href="`${publicPath}files/ua_pay/questionnaire.docx`" style="text-decoration: none">-->
<!--                    <v-btn @click.stop="" class="primary body-2 btn-center" depressed min-width="160px" :class="`${$vuetify.breakpoint.xsOnly ? 'd-block' : 'ml-1'}`">-->
<!--                      <v-icon left size="21px">mdi-file-word</v-icon>-->
<!--                      Опитувальник-->
<!--                    </v-btn>-->
<!--                  </a>-->
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="px-5 py-4">
                <v-card-title class="pl-1 py-1">
                  <p class="ma-0 subtitle-1">Політика конфендиційності</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <p>
                    Торговець гарантує безпеку конфіденційної інформації користувача. Ми використовуємо адміністративні, технічні та фізичні засоби безпеки, спрямовані на захист конфіденційної інформації, яку ми збираємо або яку Ви передаєте нам від випадкового, незаконного або несанкціонованого видалення, втрати, несанкціонованої зміни, доступу, розкриття або неналежного використання.
                    На Сайті застосовується технологія захисту від несанкціонованого доступу за допомогою засобу безпеки за місцем фізичного знаходження Сайту. Доступ до інформації про користувачів на Сайті захищений індивідуальним логіном/паролем.
                    Для передачі даних використовується промисловий стандарт SSL-шифрування з використанням стійкої криптографії.
                    Для запобігання шахрайству з використанням реквізитів платіжних карток всі операції, в яких використовуються дані про платіжну картку, здійснюються в рамках окремого платіжного шлюзу і окремого сервера зі спеціальною системою контролю доступу.
                    При цьому персонал, який має доступ до номерів карток та іншої конфіденційної інформації, несе персональну кримінальну відповідальність за незаконні дії з номерами платіжних карток та конфіденційною інформацією користувачів.
                  </p>
                  <p>
                    The merchant guarantees the security of the user's confidential information. We use administrative, technical and physical security measures to protect sensitive information that we collect or transmit to us from accidental, unlawful or unauthorized removal, loss, unauthorized modification, access, disclosure or misuse.
                    The site uses technology to protect against unauthorized access by means of security at the physical location of the Site. Access to user information on the Site is protected by an individual login / password.
                    Data is transmitted using industry standard SSL encryption using robust cryptography.
                    To prevent fraud with the use of payment card details, all transactions using the payment card information are performed within a separate payment gateway and a separate server with a special access control system.
                    In doing so, personnel who have access to card numbers and other confidential information are criminally responsible for unlawful actions with payment card numbers and users' confidential information.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </div>
</template>

<script>
  import {LocalityAPI} from '@/api/locality'
  import {cols} from '@/utils'

  export default {
    name: "About",
    data() {
      return {
          region: 2000000,
          regions: [],
          contactInfo: [
              {
                  region: 2000000,
                  contact: [
                      {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline', color: 'info'},
                      {value: '0969999999', icon: 'mdi-deskphone', color: 'error'},
                      {value: 'ternopil-support', icon: 'mdi-skype-business', color: 'primary'},
                      {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius', color: 'success'},
                  ]
              },
              {
                  region: 2300000,
                  contact: [
                      {value: 'khmelnitskiy-support@gmail.com', icon: ' mdi-email-outline'},
                      {value: '0969999999', icon: 'mdi-deskphone'},
                      {value: 'ternopil-support', icon: 'mdi-skype-business'},
                      {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
                  ]
              },
              {
                  region: 2600000,
                  contact: [
                      {value: 'chernivtsy-support@gmail.com', icon: ' mdi-email-outline'},
                      {value: '0969999999', icon: 'mdi-deskphone'},
                      {value: 'ternopil-support', icon: 'mdi-skype-business'},
                      {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
                  ]
              },
              {
                  region: 900000,
                  contact: [
                      {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline'},
                      {value: '0969999999', icon: 'mdi-deskphone'},
                      {value: 'ternopil-support', icon: 'mdi-skype-business'},
                      {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
                  ]
              },
              {
                  region: 1000000,
                  contact: [
                      {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline'},
                      {value: '0969999999', icon: 'mdi-deskphone'},
                      {value: 'ternopil-support', icon: 'mdi-skype'},
                      {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
                  ]
              }
          ],
          publicPath: process.env.BASE_URL
      }
    },
    methods: {
      async getRegions() {
        try {
            const {data} = await LocalityAPI.regions();
            this.regions = data;
        } catch (e) {
            this.$snackbar("Помилка одержання областей", 'error')
        }
      },
      replaceUrl() {
        location.replace('https://www.google.com.ua/')
      },
      cols,
    },
    computed: {
      contactData() {
        if (this.region) {
            let contactObject = this.contactInfo.filter(item => item.region === this.region);
            if (contactObject.length > 0) {
                return contactObject[0].contact;
            } else {
                return [];
            }
        } else {
            return [];
        }
      }
    },
    mounted() {
        this.getRegions();
    }
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1560px;
  }
  .border-left {
    border-left: 40px solid green;
  }
  .title-div {
    width: 80%;
    height: 62px;
    position: absolute;
    top: -29px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
  .fill-height-90 {
    height: 98%;
  }
  .btn-center {
    margin: 0 auto;
  }
  a {
    text-decoration: none;
  }
  .strong-color {
    color: rgba(0, 0, 0, 0.54);
  }
  .about {
    &::v-deep .v-btn__content .v-icon {
      color: inherit !important;
    }
  }
</style>
